import React, { Component } from "react";
import Navbar from "./Components/Navbar/Navbar";
import LandingPage from "./Components/LandingPage/LandingPage";
import { Sticky, Responsive } from "semantic-ui-react";
import { Switch, Route } from "react-router";
import ContactUs from "./Components/ContactUs/ContactUs";
import GallaryPage from "./Components/GallaryPage/GallaryPage";
import Footer from "./Components/Footer/Footer";
import AboutUs from "./Components/AboutUs/AboutUs";
// import InsulatorsPage from "./Components/InsulatorsPage/InsulatorsPage";
import ScrollToTop from "./Components/Utils/ScrollToTop";
import ManufacturingPage from "./Components/InsulatorsPage/Manufacturing/ManufacturingPage";
import SiliconeInsulatorPage from "./Components/InsulatorsPage/InsulatorTabs/SiliconeInsulatorPage";

class App extends Component {
  state = {};

  setDivContext = context => {
    this.setState({
      divContext: context
    });
  };

  render() {
    return (
      <div ref={this.setDivContext}>
        <Responsive maxWidth="991">
          <Navbar />
        </Responsive>
        <Responsive minWidth="992">
          <Sticky context={this.state.divContext}>
            <Navbar />
          </Sticky>
        </Responsive>
        <Switch>
          <ScrollToTop>
            <Route exact path="/" component={LandingPage} />
            <Route path="/contact" component={ContactUs} />
            <Route path="/manufacturing" component={ManufacturingPage} />
            <Route path="/design" component={SiliconeInsulatorPage} />
            <Route path="/gallary" component={GallaryPage} />
            <Route path="/aboutus" component={AboutUs} />
          </ScrollToTop>
        </Switch>
        <Responsive minWidth="992">
          <Footer />
        </Responsive>
      </div>
    );
  }
}

export default App;
