import React, { Component } from "react";
import { List, Container, Divider } from "semantic-ui-react";

export default class QualityPromise extends Component {
  render() {
    return (
      <div style={{ marginBottom: "2%" }}>
        <Container textAlign="center">
          <p className="large-text" style={{ fontWeight: "bold" }}>
            Our Quality Policy
          </p>
        </Container>
        <br />
        <Container textAlign="justified">
          <p className="small-text">
            We at Vistaar Industries will strive to prove our excellence in
            maintaining the reputation of our company in manufacturing and
            supplying high quality products with competitive price to our
            customers. With the implementation of advance technologies &
            improvement in our product, process, services, we drive all our
            efforts towards customer satisfaction.
          </p>
        </Container>
        <br />
        <Divider />
        <Container textAlign="left">
          <p className="small-text" style={{ fontWeight: "bold" }}>
            We believe in:
          </p>
          <List as="ul">
            <List.Item as="li" className="small-text">
              Zero Defect products & Process.
            </List.Item>
            <List.Item as="li" className="small-text">
              VISTAAR provides technical & skill development Session to
              employees and Staff.
            </List.Item>
            <List.Item as="li" className="small-text">
              VISTAAR adheres to global standards & specifications.
            </List.Item>
            <List.Item as="li" className="small-text">
              VISTAAR protects and enriches the environment during the
              manufacturing process.
            </List.Item>
            <List.Item as="li" className="small-text">
              VISTAAR believes in “On Time & Consistency in Delivery of Quality
              goods only.
            </List.Item>
          </List>
        </Container>
      </div>
    );
  }
}
