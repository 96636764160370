import React, { Component } from "react";
import { Grid, Segment } from "semantic-ui-react";
import InsulatorDesignTab from "./InsulatorDesignTab";
import InsulatorAdvantagesTab from "./InsulatorAdvantagesTab";

// const panes = [
//   {
//     menuItem: "Design",
//     render: () => (
//       <Tab.Pane>
//         <InsulatorDesignTab />
//       </Tab.Pane>
//     )
//   },
//   {
//     menuItem: "Advantages",
//     render: () => (
//       <Tab.Pane>
//         <InsulatorAdvantagesTab />
//       </Tab.Pane>
//     )
//   }
// ];

const DESIGN = "DESIGN";
const ADVANTAGES = "ADVANTAGES";

export default class InsulatorTabs extends Component {
  state = {
    selectedTab: DESIGN
  };

  handleTabClick = tabName => () => {
    this.setState({
      selectedTab: tabName
    });
  };

  decideClassName = tabName => {
    if (this.state.selectedTab === tabName) {
      return "vi-tab vi-tab-selected";
    }
    return "vi-tab";
  };

  render() {
    const { selectedTab } = this.state;
    return (
      <div>
        <Segment textAlign="center">
          <Grid>
            <Grid.Row
              columns="equal"
              style={{ paddingTop: "0px", paddingBottom: "0px" }}
            >
              <Grid.Column
                onClick={this.handleTabClick(DESIGN)}
                className={this.decideClassName(DESIGN)}
                style={{
                  paddingBottom: "1%",
                  paddingTop: "1%"
                }}
                verticalAlign="middle"
              >
                <p className="small-text" style={{ fontWeight: "bold" }}>
                  Design
                </p>
              </Grid.Column>
              <Grid.Column
                onClick={this.handleTabClick(ADVANTAGES)}
                className={this.decideClassName(ADVANTAGES)}
                verticalAlign="middle"
                style={{
                  paddingBottom: "1%",
                  paddingTop: "1%"
                }}
              >
                <p className="small-text" style={{ fontWeight: "bold" }}>
                  Advantages
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Segment size="huge">
          {selectedTab === DESIGN && <InsulatorDesignTab />}
          {selectedTab === ADVANTAGES && <InsulatorAdvantagesTab />}
        </Segment>
        {/* <Tab
          menu={{
            pointing: true,
            inverted: true,
            attached: false,
            tabular: false
          }}
          panes={panes}
        /> */}
      </div>
    );
  }
}
