import React, { Component } from "react";
import { Button, Grid, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class FourOptionsMobile extends Component {
  openDownloadUrl = type => () => {
    let url =
      "https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/Vistaar%20Industries%20-%20ISO%20Certification.pdf?alt=media&token=c59fa43e-a513-4d1a-8d1a-f50a82d1378d";
    if (type === "iso") {
      url =
        "https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/Vistaar%20Industries%20-%20ISO%20Certification.pdf?alt=media&token=c59fa43e-a513-4d1a-8d1a-f50a82d1378d";
    } else if (type === "catalog") {
      url =
        "https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/Vistaar%20Industries%20%20-%20Catalog.pdf?alt=media&token=b0fb5cab-0fbf-4f7f-81d5-4933de5eaed0";
    }
    window.open(url, "_blank");
  };

  render() {
    return (
      <Grid centered style={{ marginTop: "2%" }}>
        <Grid.Row centered>
          <Grid.Column width="4" textAlign="center">
            <Icon fitted size="small" name="setting" />
            <p
              className="small-text"
              style={{ fontWeight: "bold", marginTop: "2%" }}
            >
              Manufacturing
            </p>
            <Button size="mini" as={Link} to={"/manufacturing"}>
              Learn More
            </Button>
          </Grid.Column>
          <Grid.Column width="4" textAlign="center">
            <Icon fitted size="small" name="product hunt" />
            <p
              className="small-text"
              style={{ fontWeight: "bold", marginTop: "2%" }}
            >
              Our Products
            </p>
            <Button size="mini" as={Link} to={"/design"}>
              Know More
            </Button>
          </Grid.Column>
          <Grid.Column width="4" textAlign="center">
            <Icon fitted size="small" name="download" />
            <p
              className="small-text"
              style={{ fontWeight: "bold", marginTop: "2%" }}
            >
              Download Catalog
            </p>
            <Button size="mini" onClick={this.openDownloadUrl("catalog")}>
              Download File
            </Button>
          </Grid.Column>
          <Grid.Column width="4" textAlign="center">
            <Icon fitted size="small" name="certificate" />
            <p
              className="small-text"
              style={{ fontWeight: "bold", marginTop: "2%" }}
            >
              ISO Certification
            </p>
            <Button size="mini" onClick={this.openDownloadUrl("iso")}>
              Download File
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}
