import React, { Component } from "react";
import { Grid, Container, Image } from "semantic-ui-react";

export default class Pultrusion extends Component {
  render() {
    return (
      <div>
        <Grid width="16" stackable padded>
          <Grid.Row style={{ padding: "0px 0px 0px 0px" }}>
            <Grid.Column
              width="8"
              style={{ padding: "2% 2%" }}
              verticalAlign="middle"
            >
              <Container textAlign="center">
                <p
                  className="big-heading"
                  style={{ fontWeight: "bold", color: "orange" }}
                >
                  Pultrusion
                </p>

                <p className="large-text">
                  We manufacture FRP Rods in house using fully automated
                  Pultrusion machines which give us the great advantage of
                  controlling the quality of the Core rod for our Insulators.
                </p>
              </Container>
            </Grid.Column>
            <Grid.Column
              verticalAlign="middle"
              width="8"
              style={{ padding: "0px 0px 0px 0px" }}
            >
              <Image centered fluid src="assets/manufac/pultrusiion.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
