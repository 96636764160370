import React, { Component } from "react";
import { Grid, Container, Image } from "semantic-ui-react";

export default class Moulding extends Component {
  render() {
    return (
      <div>
        <Grid width="16" stackable>
          <Grid.Row style={{ padding: "0px 0px 0px 0px" }}>
            <Grid.Column
              width="8"
              style={{ padding: "2% 2%" }}
              verticalAlign="middle"
            >
              <Container textAlign="center">
                <p
                  className="big-heading"
                  style={{ fontWeight: "bold", color: "orange" }}
                >
                  Moulding
                </p>

                <p className="large-text">
                  Housing is formed by high pressure injection moulding silicone
                  rubber onto the core as one continuous part or step moulding
                  process. Both housing and core are chemically bonded together
                  during the vulcanization process. The strength of this bond is
                  greater than the tearing strength of the silicone housing
                  material itself.
                </p>
              </Container>
            </Grid.Column>
            <Grid.Column
              verticalAlign="middle"
              width="8"
              // style={{ padding: "0px 0px 0px 0px" }}
            >
              <Image centered fluid src="assets/manufac/moulding.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
