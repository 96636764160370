import React, { Component } from "react";
import { Grid, Form, Segment, Button, Header } from "semantic-ui-react";

let warningStatus = false;
let resultMessage = "";
let sendingMessage = false;

export default class MapForm extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };

  state = {
    name: "",
    email: "",
    phone: "",
    message: ""
  };

  sendMessage = data => {
    try {
      sendingMessage = true;
      resultMessage = "";
      this.setState({});
      fetch(
        "https://us-central1-vistaar-industries-website.cloudfunctions.net/sendEmail",
        {
          method: "POST", // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(res => res.json())
        .then(data => {
          resultMessage = data.result;
          sendingMessage = false;
          this.setState({});
        });
    } catch (e) {
      resultMessage = "There was an error Sending Message!";
      sendingMessage = false;
      this.setState({});
    }
  };

  handleFieldChange = e => {
    warningStatus = false;
    resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState(prevState => {
      return {
        [fieldName]: value
      };
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach(key => {
        let value = this.state[key];
        if (value.trim() === "") {
          valid = false;
        }
      });
    }
    return valid;
  };

  render() {
    return (
      <div>
        <Grid stackable>
          <Grid.Row centered>
            <Grid.Column width="6">
              <Segment loading={sendingMessage}>
                <Form onSubmit={this.handleFormSubmit} warning={warningStatus}>
                  {warningStatus && (
                    <Header> Please fill data correctly!</Header>
                  )}
                  <Header color="green">{`${resultMessage}`}</Header>
                  <Form.Field>
                    <label>Name</label>
                    <input
                      placeholder="Name"
                      type="text"
                      value={this.state.name}
                      name="name"
                      onChange={this.handleFieldChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Phone</label>
                    <input
                      placeholder="Phone"
                      type="number"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleFieldChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Email</label>
                    <input
                      placeholder="Email"
                      type="text"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleFieldChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Message</label>
                    <textarea
                      name="message"
                      value={this.state.message}
                      onChange={this.handleFieldChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Button
                      color="orange"
                      type="submit"
                      // type="button"
                      // onClick={this.handleFormSubmit}
                    >
                      Send Message
                    </Button>
                  </Form.Field>
                </Form>
              </Segment>
            </Grid.Column>
            <Grid.Column width="10">
              <div style={{ height: "70vh", width: "100%" }}>
                <iframe
                  title="Vistaar Industries"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19997.293581302296!2d74.84900603788464!3d30.593935498464713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391751773bf8fd57%3A0xecc4ffc57777e874!2sVistaar%20Industries!5e0!3m2!1sen!2sin!4v1570971676822!5m2!1sen!2sin"
                  frameborder="0"
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
