import React, { Component } from "react";
import Banner from "./Banner/Banner";
import { Container, Button, Responsive } from "semantic-ui-react";
import MobileBanner from "./MobileBanner/MobileBanner";

import { Link } from "react-router-dom";
import FourOptionsMobile from "../FourOptions/FourOptionsMobile";

class LandingPage extends Component {
  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.setState({});
  }

  updateWithWindowSize() {
    window.onresize = () => {
      if (document.body.clientWidth < 991) {
        document.body.style.overflow = "auto";
      } else {
        document.body.style.overflow = "hidden";
      }
    };
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  render() {
    this.updateWithWindowSize();
    return (
      <div>
        <Responsive minWidth="992">
          <Banner />
        </Responsive>
        <Responsive maxWidth="991">
          <MobileBanner />
          <FourOptionsMobile />

          <br />
          <div
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#FFFFFF",
              marginBottom: "4%"
            }}
          >
            <p
              style={{ width: "100%", textAlign: "center", marginTop: "2%" }}
              className="big-heading"
            >
              About Us
            </p>

            <Container textAlign="justified">
              <p className="large-text">
                Vistaar Industries is a world class manufacturer of cost
                effective, high quality and high voltage Polymer Insulators. All
                Polymer products are manufactured to stringent quality
                requirements defined by Vistaar Industry’s Quality management
                which follows Specification such as IEC, Railways, IS and ASTM ,
                hence assuring our customers of exceptional value, consistent
                quality & on time delivery.
              </p>
            </Container>
            <Button as={Link} to={"/aboutus"} style={{ marginTop: "2%" }}>
              Read More
            </Button>
          </div>
        </Responsive>
      </div>
    );
  }
}

export default LandingPage;
