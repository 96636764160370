import React, { Component } from "react";
import { Menu, Image, Segment, Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
const HOME = "home";
const INSULATORS = "insulators";
const GALLARY = "gallary";
const ABOUT_US = "about_us";
const CONTACT_US = "contact_us";

class Navbar extends Component {
  state = {
    activeItem: HOME
  };

  changeSelectedItem = newItem => () => {
    this.setState({
      activeItem: newItem
    });
  };

  render() {
    return (
      // <div style={{ display: "block" }}>
      <Segment style={{ padding: "0px", border: "0px" }}>
        <Menu color="yellow" secondary stackable>
          <Menu.Item as={Link} to={"/"} style={{ padding: "5px 5px 5px 5px" }}>
            <Image
              size={isMobile ? "small" : "medium"}
              src="./assets/logo_vi.png"
              alt="Vistaar Industries"
            />
          </Menu.Item>
          <Menu.Menu position="right" style={{ paddingRight: "2%" }}>
            <Menu.Item
              as={Link}
              to={"/"}
              name="Home"
              active={this.state.activeItem === HOME}
              onClick={this.changeSelectedItem(HOME)}
            />
            <Menu.Item>
              <Dropdown item text="Insulators">
                <Dropdown.Menu>
                  <Dropdown.Item
                    as={Link}
                    to={"/manufacturing"}
                    onClick={this.changeSelectedItem(INSULATORS)}
                  >
                    Manufacturing
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to={"/design"}
                    onClick={this.changeSelectedItem(INSULATORS)}
                  >
                    Design and Advantages
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
            {/* <Menu.Item
                as={Link}
                to={"/insulators"}
                name="Insulators"
                active={this.state.activeItem === INSULATORS}
                onClick={this.changeSelectedItem(INSULATORS)}
              /> */}
            <Menu.Item
              as={Link}
              to={"/gallary"}
              name="Gallary"
              active={this.state.activeItem === GALLARY}
              onClick={this.changeSelectedItem(GALLARY)}
            />
            <Menu.Item
              as={Link}
              to={"/aboutus"}
              name="About Us"
              active={this.state.activeItem === ABOUT_US}
              onClick={this.changeSelectedItem(ABOUT_US)}
            />
            <Menu.Item
              as={Link}
              to={"/contact"}
              name="Contact Us"
              active={this.state.activeItem === CONTACT_US}
              onClick={this.changeSelectedItem(CONTACT_US)}
            />
          </Menu.Menu>
        </Menu>
      </Segment>
      // </div>
    );
  }
}

export default Navbar;
