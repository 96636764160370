import React, { Component } from "react";
import { Grid, Segment, Progress, Button, Icon } from "semantic-ui-react";
import Moulding from "./Moulding";
import Crimping from "./Crimping";
import Marking from "./Marking";
import Pultrusion from "./Pultrusion";
import InforBarHeader from "../../InfoBarHeader/InforBarHeader";

const steps = ["Moulding", "Crimping", "Pultrusion", "Marking"];

export default class ManufacturingPage extends Component {
  state = {
    stepIndex: 0,
    percentage: 25
  };

  positionIndex = type => () => {
    this.setState(prevState => {
      let nextIndex = prevState.stepIndex;
      if (type === 1 && prevState.stepIndex < 3) {
        nextIndex = nextIndex + 1;
      }
      if (type === 0 && prevState.stepIndex > 0) {
        nextIndex = nextIndex - 1;
      }
      return {
        stepIndex: nextIndex,
        percentage: 25 * (nextIndex + 1)
      };
    });
  };

  getPageForIndex = () => {
    switch (this.state.stepIndex) {
      case 0:
        return <Moulding />;
      case 1:
        return <Crimping />;
      case 2:
        return <Pultrusion />;
      case 3:
        return <Marking />;
      default:
        break;
    }
  };

  render() {
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <div className="page-header-parent">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/assets/insulators.jpg"
            alt=""
          />
          {/* <div
            className="dark-tint"
            style={{ minHeight: "100%", display: "block" }}
          /> */}
          <p className="page-header-content company-name">
            Manufacturing Process
          </p>
          <InforBarHeader />
        </div>
        {/* <div style={{ textAlign: "center", paddingTop: "2%" }}>
          <p className="big-heading">Steps in Manufacturing</p>
        </div> */}

        <Grid padded>
          <Grid.Row textAlign="center" verticalAlign="middle">
            <Grid.Column
              only="computer"
              width="4"
              textAlign="center"
              floated="left"
            >
              <Button
                color="green"
                icon
                labelPosition="left"
                onClick={this.positionIndex(0)}
              >
                <Icon name="arrow circle left" />
                Previous
              </Button>
            </Grid.Column>
            <Grid.Column computer="8" tablet="16" mobile="16">
              <Progress
                color="orange"
                value={this.state.stepIndex + 1}
                total="4"
                progress="ratio"
                label={steps[this.state.stepIndex]}
                style={{ margin: "0% 2%" }}
              />
            </Grid.Column>
            <Grid.Column
              only="computer"
              width="4"
              textAlign="center"
              floated="right"
            >
              <Button
                color="green"
                icon
                labelPosition="right"
                style={{ marginRight: "4%" }}
                onClick={this.positionIndex(1)}
              >
                <Icon name="arrow circle right" />
                Next
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only="mobile tablet">
            <Grid.Column width="4" textAlign="center" floated="left">
              <Button
                color="green"
                icon
                labelPosition="left"
                onClick={this.positionIndex(0)}
              >
                <Icon name="arrow circle left" />
                Previous
              </Button>
            </Grid.Column>
            <Grid.Column width="4" textAlign="center" floated="right">
              <Button
                color="green"
                icon
                labelPosition="right"
                style={{ marginRight: "4%" }}
                onClick={this.positionIndex(1)}
              >
                <Icon name="arrow circle right" />
                Next
              </Button>
            </Grid.Column>
            <Grid.Column only="mobile" width="2" />
          </Grid.Row>
        </Grid>
        <Segment
          secondary
          style={{ display: "inline-block", width: "90%", marginBottom: "7%" }}
        >
          {this.getPageForIndex()}
        </Segment>
      </div>
    );
  }
}
