var images = document.getElementsByClassName("banner_image");
let displayingIndex = 2;

var translationAmount = 100;

window.onload = () => {
  var bannerContainer = document.getElementById("banner-container");
  if (bannerContainer != null) {
    bannerContainer.style.height = images[0].clientHeight + "px";
  }
};

window.onresize = () => {
  resizeContainer();
};

export function changeImage() {
  var component = images[displayingIndex];
  component.style.WebkitTransform = `translate(${translationAmount}%)`;
  component.style.msTransform = `translate(${translationAmount}%)`;
  component.style.transform = `translate(${translationAmount}%)`;
  component.style.transition = "transform 1s";
  component.style.WebkitTransition = "transform 1s";
  if (displayingIndex === 1) {
    displayingIndex = 2;
    translationAmount = translationAmount === 100 ? 0 : 100;
  } else {
    displayingIndex--;
  }
}

export function resizeContainer() {
  var bannerContainer = document.getElementById("banner-container");
  if (bannerContainer != null) {
    bannerContainer.style.height = images[0].clientHeight + "px";
  }
}
