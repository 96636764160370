import React, { Component } from "react";
import { changeImage, resizeContainer } from "./BannerScript";
import "./BannerStyle.css";
import { Grid, Segment } from "semantic-ui-react";

const BANNER_IMAGES = ["image_1", "image_2", "image_3"];

let intervalVariable = null;
let resizeContainerTimeout = null;

class MobileBanner extends Component {
  state = {
    selectedImageIndex: 0
  };

  initiateChangeImageTimer = () => {
    intervalVariable = setInterval(() => {
      changeImage();
    }, 4000);
  };

  componentDidMount() {
    resizeContainerTimeout = setTimeout(() => {
      resizeContainer();
    }, 200);
    this.initiateChangeImageTimer();
  }

  componentWillUnmount() {
    if (intervalVariable != null) {
      clearInterval(intervalVariable);
    }
    if (resizeContainerTimeout != null) {
      clearTimeout(resizeContainerTimeout);
    }
  }

  render() {
    return (
      <div>
        <div
          id="banner-container"
          style={{
            display: "block",
            // position: "relative",
            // display: "table",
            width: "100%"
          }}
        >
          <div>
            <img
              className="banner_image_mobile"
              src={`/assets/banners/${BANNER_IMAGES[0]}.jpg`}
              alt="Banner Images"
            />
            <img
              className="banner_image_mobile"
              src={`/assets/banners/${BANNER_IMAGES[1]}.jpg`}
              alt="Banner Images"
            />
            <img
              className="banner_image_mobile"
              src={`/assets/banners/${BANNER_IMAGES[2]}.jpg`}
              alt="Banner Images"
            />
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              width: "100%",
              marginTop: "4%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Grid centered style={{ width: "100%" }}>
              <Grid.Row centered style={{ paddingBottom: "0px" }}>
                <Grid.Column textAlign="center" width="16">
                  <div className="company-name">
                    VISTAAR
                    <p style={{ color: "#f5bd20" }}>INDUSTRIES</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row
                only="computer tablet"
                centered
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <Grid.Column computer="10" tablet="10">
                  <img src="/assets/banner_logo.png" alt="Center Logo" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Segment
                  size="mini"
                  style={{
                    background: "#f5bd20",
                    width: "70%",
                    paddingRight: "4%",
                    paddingLeft: "4%"
                  }}
                >
                  <p
                    className="large-text"
                    style={{ color: "white", fontWeight: "600" }}
                  >
                    Polymer Insulators & Allied Products
                  </p>
                </Segment>
              </Grid.Row>
              <Grid.Row
                only="computer"
                verticalAlign="middle"
                centered
                stle={{ paddingTop: "0px" }}
              >
                <Grid.Column width="16" textAlign="center">
                  <pre className="company-slogan">
                    {`Power   Performance   Progress`}
                  </pre>
                </Grid.Column>
                {/* <Grid.Column width="4" textAlign="center">
                  <p className="company-slogan">Performance</p>
                </Grid.Column>
                <Grid.Column width="4" textAlign="left">
                  <p className="company-slogan">Progress</p>
                </Grid.Column> */}
              </Grid.Row>
            </Grid>
            <Grid />
          </div>
        </div>
      </div>
    );
  }
}

export default MobileBanner;
