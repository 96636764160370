import React, { Component } from "react";
import { List, Container, Divider } from "semantic-ui-react";

export default class WhyUs extends Component {
  render() {
    return (
      <div style={{ marginBottom: "2%" }}>
        <Container textAlign="center">
          <p className="large-text" style={{ fontWeight: "bold" }}>
            {/* Quality and Service */}
            Why choose us?
          </p>
        </Container>
        <br />
        <Container textAlign="justified">
          <p className="small-text">
            “The name is a Guarantee” this is what you expect from the India’s
            one of the biggest Polymer Insulator company. The insulator starts
            with rigorous inspection of raw materials and components. Also with
            constant in process checks and tests including pre and post
            production testing, analysis, inspection etc., we ensure that
            VISTAAR’s quality product will provide years of trouble free
            service.
          </p>
        </Container>
        <br />
        <Container textAlign="center">
          <p className="large-text" style={{ fontWeight: "bold" }}>
            Our Team
          </p>
        </Container>
        <br />
        <Container textAlign="center">
          <p className="small-text">
            The VISTAAR team of experts has years of experience in design and
            manufacture of polymers. This enables us to utilize a proven
            technology of modeling process and latest technology as well.
          </p>
        </Container>
        <br />
        <Divider />
        <Container textAlign="left">
          <p className="small-text" style={{ fontWeight: "bold" }}>
            Why Vistaar Industry's Insulators are Superior:
          </p>
          <List as="ul">
            <List.Item as="li" className="small-text">
              Well Engineered and Optimized world class design.
            </List.Item>
            <List.Item as="li" className="small-text">
              Impenetrable to humidity and moisture design by molding over end
              fittings.
            </List.Item>
            <List.Item as="li" className="small-text">
              Pressure controlled excellent insulating behavior without pressure
              force control loop.
            </List.Item>
            <List.Item as="li" className="small-text">
              World renowned plant & machinery and moulds.
            </List.Item>
            <List.Item as="li" className="small-text">
              Best European & U.S made silicone compound.
            </List.Item>
            <List.Item as="li" className="small-text">
              Core pull-out load 100% to 120% of specific mechanical load.
            </List.Item>
            <List.Item as="li" className="small-text">
              Aero Dynamic Sheds Design.
            </List.Item>
          </List>
        </Container>
      </div>
    );
  }
}
