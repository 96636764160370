import React, { Component } from "react";
import {
  Grid,
  Segment,
  Image,
  Container,
  Responsive,
  List
} from "semantic-ui-react";
import InforBarHeader from "../InfoBarHeader/InforBarHeader";
import QualityPromise from "./InfoTabs/QualityPromise";
import WhyUs from "./InfoTabs/WhyUs";

class AboutUs extends Component {
  render() {
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <div className="page-header-parent">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/assets/about_us.jpg"
            alt=""
          />
          {/* <div
            className="dark-tint"
            style={{ minHeight: "100%", display: "block" }}
          /> */}
          <p className="page-header-content company-name">About Us</p>
          <InforBarHeader />
        </div>
        {/* <div style={{ textAlign: "center", paddingTop: "2%" }}>
          <p className="big-heading">Company Overview</p>
        </div> */}

        <Segment
          secondary
          style={{ display: "inline-block", width: "90%", marginBottom: "2%" }}
        >
          <Grid width="16" stackable>
            <Grid.Row stretched divided style={{ padding: "0px 0px 0px 0px" }}>
              <Grid.Column width="10" style={{ padding: "2% 2%" }}>
                <Container textAlign="justified">
                  <p
                    className="large-text"
                    style={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Company Overview
                  </p>
                  <p className="large-text">
                    Vistaar Industries is a world class manufacturer of cost
                    effective, high quality and high voltage Polymer Insulators.
                    All Polymer products are manufactured to stringent quality
                    requirements defined by Vistaar Industry’s Quality
                    management which follows Specification No: IEC 61109:2008,
                    Indian Railway Specification No: TI/SPC/OHF/INSCOM/1070,
                    Rural Electrification Corporation Ltd. Specification No:
                    76/2006 and assuring our customer of exceptional value,
                    consistent quality & on time delivery.
                  </p>
                </Container>
                <Responsive minWidth="992">
                  <Segment textAlign="left">
                    <List bulleted animated>
                      <List.Item onClick={() => {}}>
                        Our Quality Policy
                      </List.Item>
                      <List.Item onClick={() => {}}>Why Choose Us?</List.Item>
                    </List>
                  </Segment>
                </Responsive>
              </Grid.Column>
              {/* <Responsive maxWidth="991"> */}
              <Grid.Column width="6" style={{ padding: "0px 0px 0px 0px" }}>
                <Image
                  style={{ width: "100%", height: "100%" }}
                  centered
                  size="medium"
                  src="assets/industry_image_1.jpg"
                />
              </Grid.Column>
              {/* </Responsive> */}
              {/* <Responsive minWidth="992">
                <Grid.Column
                  className="industry_image"
                  width="6"
                  style={{ padding: "0px 0px 0px 0px" }}
                >
                  {/* <Image size="medium" src="assets/industry_image_1.jpg" /> */}
              {/* </Grid.Column>
              </Responsive> */}
            </Grid.Row>
          </Grid>
        </Segment>

        {/* <Segment
          secondary
          style={{ display: "inline-block", width: "90%", marginBottom: "2%" }}
        >
          <InfoTabs />
        </Segment> */}
        <Segment
          id="quality_promise"
          secondary
          style={{ display: "inline-block", width: "90%", marginBottom: "2%" }}
        >
          <QualityPromise />
        </Segment>

        <Segment
          id="why_us"
          secondary
          style={{ display: "inline-block", width: "90%", marginBottom: "2%" }}
        >
          <WhyUs />
        </Segment>

        <Segment
          style={{
            display: "inline-block",
            width: "90%",
            marginBottom: "4%",
            background: "#f5bd20"
          }}
        >
          <Container textAlign="center">
            <p className="large-text" style={{ fontWeight: "bold" }}>
              QMS CERTIFICATION
            </p>
            <p className="small-text">
              Vistaar Industries is certified by BSI for ISO 9001:2015 with RIR
              (AIS) accreditation for scope “The Design and Manufacture of
              polymer insulators and allied polymer products for Electrical
              Applications”
            </p>
          </Container>
        </Segment>
      </div>
    );
  }
}

export default AboutUs;
