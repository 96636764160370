import React, { Component } from "react";
import "./ContactUsScript.css";
import { Grid, Segment, Container } from "semantic-ui-react";
import InforBarHeader from "../InfoBarHeader/InforBarHeader";
import MapForm from "./MapAndForm/MapForm";

class ContactUs extends Component {
  render() {
    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <div className="page-header-parent">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/assets/contact_us.png"
            alt=""
          />
          {/* <div
            className="dark-tint"
            style={{ minHeight: "100%", display: "block" }}
          /> */}
          <p className="page-header-content company-name">Get in Touch</p>
          <InforBarHeader />
        </div>
        {/* <div style={{ textAlign: "center", paddingTop: "2%" }}>
          <p className="big-heading">Get in Touch</p>
        </div> */}

        <Segment
          secondary
          style={{ display: "inline-block", width: "70%", marginTop: "2%" }}
        >
          <Grid width="16" stackable>
            <Grid.Row divided>
              <Grid.Column width="8">
                <Container textAlign="left">
                  <p className="large-text" style={{ fontWeight: "bold" }}>
                    Address
                  </p>
                  <p className="small-text">
                    Opposite Government Polytechnic College, Deviwala Road,
                    Kotkapura, Pin-151204, Distt. Faridkot, Punjab, INDIA
                  </p>
                </Container>
              </Grid.Column>
              <Grid.Column width="4">
                <Container textAlign="left">
                  <p className="large-text" style={{ fontWeight: "bold" }}>
                    Email
                  </p>
                  <p className="small-text">inquiry@vistaar.ind.in</p>
                  <p className="small-text">sales@vistaar.ind.in</p>
                </Container>
              </Grid.Column>
              <Grid.Column width="4">
                <Container textAlign="left">
                  <p className="large-text" style={{ fontWeight: "bold" }}>
                    Phone
                  </p>
                  <p className="small-text">(+91)98555-52196</p>
                  <p className="small-text">(+91)98152-00990</p>
                </Container>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <div
          style={{
            paddingLeft: "4%",
            paddingTop: "2%",
            paddingRight: "4%",
            marginBottom: "4%"
          }}
        >
          <MapForm />
        </div>
      </div>
    );
  }
}

export default ContactUs;
