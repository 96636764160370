import React, { Component } from "react";
import { changeImage, resizeContainer } from "./BannerScript";
import "./BannerStyle.css";
import { Grid, Segment, Icon, Button } from "semantic-ui-react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

let intervalVariable = null;
let resizeContainerTimeout = null;

class Banner extends Component {
  state = {
    selectedImageIndex: 0
  };

  initiateChangeImageTimer = () => {
    intervalVariable = setInterval(() => {
      changeImage();
    }, 4000);
  };

  componentDidMount() {
    resizeContainerTimeout = setTimeout(() => {
      resizeContainer();
    }, 200);
    this.initiateChangeImageTimer();
  }

  componentWillUnmount() {
    if (intervalVariable != null) {
      clearInterval(intervalVariable);
    }
    if (resizeContainerTimeout != null) {
      clearTimeout(resizeContainerTimeout);
    }
  }

  openDownloadUrl = type => () => {
    let url =
      "https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/Vistaar%20Industries%20-%20ISO%20Certification.pdf?alt=media&token=c59fa43e-a513-4d1a-8d1a-f50a82d1378d";
    if (type === "iso") {
      url =
        "https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/Vistaar%20Industries%20-%20ISO%20Certification.pdf?alt=media&token=c59fa43e-a513-4d1a-8d1a-f50a82d1378d";
    } else if (type === "catalog") {
      url =
        "https://firebasestorage.googleapis.com/v0/b/vistaar-industries-website.appspot.com/o/Vistaar%20Industries%20%20-%20Catalog.pdf?alt=media&token=b0fb5cab-0fbf-4f7f-81d5-4933de5eaed0";
    }
    window.open(url, "_blank");
  };

  render() {
    return (
      <div>
        <div
          id="banner-container"
          style={{
            display: "block",
            // position: "relative",
            // display: "table",
            width: "100%"
          }}
        >
          <div>
            <div
              id="image_1"
              className="banner_image"
              // src={`/assets/banners/${BANNER_IMAGES[0]}.jpg`}
              // alt="Banner Images"
            />
            <div
              id="image_2"
              className="banner_image"
              // src={`/assets/banners/${BANNER_IMAGES[1]}.jpg`}
              // alt="Banner Images"
            />
            <div
              id="image_3"
              className="banner_image"
              // src={`/assets/banners/${BANNER_IMAGES[2]}.jpg`}
              // alt="Banner Images"
            />
          </div>
          <div
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              width: "100%",
              marginTop: "4%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Grid centered style={{ width: "100%" }}>
              <Grid.Row centered style={{ paddingBottom: "0px" }}>
                <Grid.Column textAlign="center" width="16">
                  <div className="company-name">
                    VISTAAR
                    <p style={{ color: "#f5bd20" }}>INDUSTRIES</p>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row
                only="computer tablet"
                centered
                style={{ paddingTop: "0px", paddingBottom: "0px" }}
              >
                <Grid.Column computer="10" tablet="10">
                  <img src="/assets/banner_logo.png" alt="Center Logo" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered>
                <Segment
                  size="mini"
                  style={{
                    background: "#f5bd20",
                    width: "70%",
                    paddingRight: "4%",
                    paddingLeft: "4%"
                  }}
                >
                  <p
                    className="large-text"
                    style={{ color: "white", fontWeight: "600" }}
                  >
                    Polymer Insulators & Allied Products
                  </p>
                </Segment>
              </Grid.Row>
              <Grid.Row
                only="computer"
                verticalAlign="middle"
                centered
                stle={{ paddingTop: "0px" }}
              >
                <Grid.Column width="16" textAlign="center">
                  <pre className="company-slogan">
                    {`Power   Performance   Progress`}
                  </pre>
                </Grid.Column>
                {/* <Grid.Column width="4" textAlign="center">
                  <p className="company-slogan">Performance</p>
                </Grid.Column>
                <Grid.Column width="4" textAlign="left">
                  <p className="company-slogan">Progress</p>
                </Grid.Column> */}
              </Grid.Row>
            </Grid>
            <Grid />
          </div>
          <div
            style={{
              width: "100%",
              position: "absolute",
              bottom: "0px",
              backgroundColor: "rgba(255,255,255,0.7)",
              padding: "2% 2%"
            }}
          >
            <Grid>
              <Grid.Row divided columns="equal" style={{ padding: "0px 0px" }}>
                <Grid.Column textAlign="center">
                  <Icon
                    fitted
                    size={isMobile ? "small" : "huge"}
                    name="setting"
                  />
                  <p
                    className="small-text"
                    style={{ fontWeight: "bold", marginTop: "2%" }}
                  >
                    Manufacturing
                  </p>
                  <Button
                    color="orange"
                    size={isMobile ? "tiny" : "medium"}
                    as={Link}
                    to={"/manufacturing"}
                  >
                    Learn More
                  </Button>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Icon
                    fitted
                    size={isMobile ? "small" : "huge"}
                    name="product hunt"
                  />
                  <p
                    className="small-text"
                    style={{ fontWeight: "bold", marginTop: "2%" }}
                  >
                    Our Products
                  </p>
                  <Button
                    color="orange"
                    size={isMobile ? "mini" : "medium"}
                    as={Link}
                    to={"/design"}
                  >
                    Know More
                  </Button>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Icon
                    fitted
                    size={isMobile ? "small" : "huge"}
                    name="download"
                  />
                  <p
                    className="small-text"
                    style={{ fontWeight: "bold", marginTop: "2%" }}
                  >
                    Download Catalog
                  </p>
                  <Button
                    color="orange"
                    size={isMobile ? "mini" : "medium"}
                    onClick={this.openDownloadUrl("catalog")}
                  >
                    Download
                  </Button>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <Icon
                    fitted
                    size={isMobile ? "small" : "huge"}
                    name="certificate"
                  />
                  <p
                    className="small-text"
                    style={{ fontWeight: "bold", marginTop: "2%" }}
                  >
                    ISO Certification
                  </p>
                  <Button
                    color="orange"
                    size={isMobile ? "mini" : "medium"}
                    onClick={this.openDownloadUrl("iso")}
                  >
                    Download
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}

export default Banner;
