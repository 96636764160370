import React, { Component } from "react";
import { Grid, Container, Image } from "semantic-ui-react";

export default class Crimping extends Component {
  render() {
    return (
      <div>
        <Grid width="16" stackable>
          <Grid.Row style={{ padding: "0px 0px 0px 0px" }}>
            <Grid.Column
              width="8"
              style={{ padding: "2% 2%" }}
              verticalAlign="middle"
            >
              <Container textAlign="center">
                <p
                  className="big-heading"
                  style={{ fontWeight: "bold", color: "orange" }}
                >
                  Crimping
                </p>

                <p className="large-text">
                  End fittings are then assembled by a pressure controlled
                  multi-step crimping process. A patented crimp control
                  technology prevents damaging the fiber glass rod while
                  achieving maximum mechanical strength. The remaining
                  interfacial gap is sealed with a tracking and erosion
                  resistant sealant to avoid moisture ingress to the fiber glass
                  rod
                </p>
              </Container>
            </Grid.Column>
            <Grid.Column
              verticalAlign="middle"
              width="8"
              // style={{ padding: "0px 0px 0px 0px" }}
            >
              <Image centered fluid src="assets/manufac/crimping.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
