import React, { Component } from "react";
import { Container, Image, Grid } from "semantic-ui-react";

export default class InsulatorAdvantagesTab extends Component {
  render() {
    return (
      <div style={{ marginBottom: "2%" }}>
        <Grid padded stackable>
          <Grid.Row centered>
            <Grid.Column width={12}>
              <Image centered size="huge" src="assets/comparison.png" />
            </Grid.Column>
            <Grid.Column width="4" verticalAlign="middle">
              <Image centered size="huge" src="assets/comparison_por.jpg" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Container textAlign="center">
              In the shown figure, the composite silicone insulator has been in
              service under very heavy pollution conditions. The porcelain
              insulator (Fig. 1) had to be greased twice per year & showed
              considerable partial discharge activity in comparison to Silicon
              Rubber Insulator (Fig. 2).
            </Container>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
