import React, { Component } from "react";
import { Grid, Image, List, Icon, Segment, Container } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <div
          style={{
            display: "block",
            position: "relative",
            paddingLeft: "2%",
            paddingRight: "2%",
            backgroundImage: `url("/assets/industry_image_1.jpg")`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center top",
            backgroundSize: "cover"
          }}
        >
          <div className="dark-tint" />
          <div className="dark-tint" />

          <Grid>
            <Grid.Row centered>
              <Grid.Column width="8" textAlign="center">
                <Image size="medium" src="/assets/logo_vi.png" centered />
                <List
                  horizontal
                  style={{
                    color: "white",
                    textAlign: "center",
                    marginTop: "2%"
                  }}
                >
                  <List.Item>
                    <List.Content
                      as={NavLink}
                      to={"/"}
                      style={{ color: "white" }}
                    >
                      <Icon name="home" />
                      Home
                    </List.Content>
                  </List.Item>
                  {/* <List.Item>
                    <List.Content
                      as={NavLink}
                      to={"/insulators"}
                      style={{ color: "white" }}
                    >
                      <Icon name="product hunt" />
                      Insulators
                    </List.Content>
                  </List.Item> */}
                  <List.Item>
                    <List.Content
                      as={NavLink}
                      to={"/gallary"}
                      style={{ color: "white" }}
                    >
                      <Icon name="photo" />
                      Gallary
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content
                      as={NavLink}
                      to={"/contact"}
                      style={{ color: "white" }}
                    >
                      <Icon name="address book" />
                      Contact Us
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content
                      as={NavLink}
                      to={"/aboutus"}
                      style={{ color: "white" }}
                    >
                      <Icon name="info circle" />
                      About Us
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width="8">
                <Segment secondary>
                  <Container textAlign="center">
                    <p className="large-text">Address</p>
                    <p className="small-text">
                      Opposite Government Polytechnic College, Deviwala Road,
                      Kotkapura, Pin-151204, Distt. Faridkot, Punjab, INDIA
                    </p>
                  </Container>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Segment>
          <Container textAlign="center">
            <p className="small-text">Copyright © [2019] Vistaar Industries</p>
            <p className="small-text">
              Designed and Developed by{" "}
              <b style={{ fontWeight: "bold" }}>RetroPortal Studio</b>
            </p>
          </Container>
        </Segment>
      </div>
    );
  }
}
