import React, { Component } from "react";
import { Grid, Segment, Header } from "semantic-ui-react";
import InforBarHeader from "../InfoBarHeader/InforBarHeader";
const imageArray = [
  "image_1",
  "image_2",
  "image_3",
  "image_4",
  "image_5",
  "image_6",
  "image_7",
  "image_8",
  "image_9",
  "image_10"
];

class GallaryPage extends Component {
  state = {
    selectedIndex: 1
  };

  changeSelectedIndex = index => () => {
    this.setState({
      selectedIndex: imageArray.indexOf(index)
    });
  };

  render() {
    return (
      <div>
        <div className="page-header-parent">
          {/* <img
            style={{ width: "100%", height: "100%" }}
            src="/assets/gallary.jpg"
            alt=""
          /> */}
          {/* <div
            className="dark-tint"
            style={{ minHeight: "100%", display: "block" }}
          /> */}

          {/* <p className="page-header-content company-name">Gallary</p> */}
          <InforBarHeader />
        </div>

        <Grid
          padded
          id="gallary_holder"
          style={{
            backgroundImage: `url("/assets/background-blur.jpg")`,
            backgroundAttachment: "fixed",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            paddingTop: "2%"
          }}
        >
          <Grid.Row
            className="gallary-background"
            style={{
              padding: "4% 4%"
            }}
            centered
          >
            <Grid.Column width="12" verticalAlign="middle" textAlign="center">
              <img
                id="main_image"
                style={{ width: "100%" }}
                src={`/assets/gallary/${
                  imageArray[this.state.selectedIndex]
                }.png`}
                alt=""
              />
            </Grid.Column>
            <Grid.Column
              only="computer tablet"
              width="4"
              verticalAlign="middle"
            >
              <Segment
                secondary
                raised
                style={{ backgroundColor: "#000000", opacity: "0.8" }}
              >
                <Header block>Scroll to Select</Header>
                <div
                  id="scroll_holder"
                  style={{
                    padding: "2%",
                    height: "400px",
                    overflow: "auto"
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tbody>
                      {imageArray.map((image, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <img
                                style={{ width: "100%" }}
                                className="scroll_image"
                                src={`/assets/gallary/${image}.png`}
                                alt=""
                                onClick={this.changeSelectedIndex(image)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            centered
            only="mobile"
            width="16"
            verticalAlign="middle"
            style={{ backgroundColor: "black" }}
          >
            {imageArray.map((image, index) => {
              return (
                <Grid.Column key={index} width="5">
                  <img
                    style={{ width: "100%" }}
                    className="scroll_image"
                    src={`/assets/gallary/${image}.png`}
                    alt=""
                    onClick={this.changeSelectedIndex(image)}
                  />
                </Grid.Column>
              );
            })}
            {/* <Segment>
              <div
                id="scroll_holder"
                style={{
                  padding: "2%",
                  width: "100%",
                  height: "100%",
                  overflow: "auto"
                }}
              >
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      {imageArray.map((image, index) => {
                        return (
                          <td key={index}>
                            <img
                              style={{ width: "100%" }}
                              className="scroll_image"
                              src={`/assets/gallary/${image}.png`}
                              alt=""
                              onClick={this.changeSelectedIndex(image)}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </Segment> */}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default GallaryPage;
