import React, { Component } from "react";
import { Grid, Icon, Button, Modal } from "semantic-ui-react";
import ContactForm from "../ContactUs/MapAndForm/ContactForm";

export default class InforBarHeader extends Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: "0px",
          width: "100%",
          display: "table",
          background: "#111111",
          textAlign: "center"
        }}
      >
        <Grid style={{ display: "table-cell", verticalAlign: "middle" }}>
          <Grid.Row style={{ paddingTop: "1%", paddingBottom: "1%" }}>
            <Grid.Column
              computer="5"
              mobile="8"
              textAlign="center"
              verticalAlign="middle"
            >
              <p className="small-text" style={{ color: "#FFFFFF" }}>
                Email{" "}
                <span>
                  <Icon name="mail" color="orange" />
                </span>
                inquiry@vistaar.ind.in
              </p>
            </Grid.Column>
            <Grid.Column computer="5" mobile="8" verticalAlign="middle">
              <p className="small-text" style={{ color: "#FFFFFF" }}>
                Phone{" "}
                <span>
                  <Icon name="phone" color="orange" />
                </span>
                (+91)98152-00990
              </p>
            </Grid.Column>
            <Grid.Column computer="5" only="computer" verticalAlign="middle">
              <Modal trigger={<Button size="tiny">Make Enquiry</Button>}>
                <Modal.Header>Contact Form</Modal.Header>
                <Modal.Content>
                  <ContactForm />
                </Modal.Content>
              </Modal>
              {/* <Button as={Link} to="/contact" size="small" color="orange">
                Make an Enquiry
              </Button> */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
