import React, { Component } from "react";
import { Segment, List, Grid, Container } from "semantic-ui-react";
import InsulatorTabs from "./InsulatorTabs";
import InforBarHeader from "../../InfoBarHeader/InforBarHeader";

export default class SiliconeInsulatorPage extends Component {
  render() {
    return (
      <div style={{ marginBottom: "4%" }}>
        <div className="page-header-parent">
          <img
            style={{ width: "100%", height: "100%" }}
            src="/assets/insulators.jpg"
            alt=""
          />
          {/* <div
            className="dark-tint"
            style={{ minHeight: "100%", display: "block" }}
          /> */}
          <p className="page-header-content company-name">
            Design & Advantages
          </p>
          <InforBarHeader />
        </div>

        <div
          style={{ textAlign: "center", paddingTop: "2%", marginBottom: "2%" }}
        >
          <p className="big-heading">Silicon Composite Insulators</p>
        </div>
        <div style={{ width: "90%", marginLeft: "5%", marginRight: "5%" }}>
          <Segment
            secondary
            textAlign="center"
            style={{
              display: "inline-block",
              width: "100%",
              marginBottom: "2%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <InsulatorTabs />
          </Segment>

          <div style={{ textAlign: "center", paddingTop: "2%" }}>
            <p className="big-heading">Our In House Facilities</p>
          </div>

          <Segment
            secondary
            style={{
              display: "inline-block",
              // width: "90%",
              marginBottom: "4%",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            textAlign="center"
          >
            <Grid padded stackable centered>
              <Grid.Row centered>
                <Grid.Column width="7">
                  <Container textAlign="center">
                    <List as="ul">
                      <List.Item as="li" className="small-text">
                        Brittle Fracture Resistance Test Setup
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Recovery of Hydrophobicity Test
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Bending Test Setup
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Galvanizing Thickness Gauge
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Rubber Content Test Setup
                      </List.Item>
                    </List>
                  </Container>
                </Grid.Column>
                <Grid.Column width="7">
                  <Container textAlign="center">
                    <List as="ul">
                      <List.Item as="li" className="small-text">
                        Dielectric Strength Test
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Rockwell Cum Brinnel Hardness Testing
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Specific Gravity Testing
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Dye Penetration
                      </List.Item>
                      <List.Item as="li" className="small-text">
                        Polymer Identification
                      </List.Item>
                    </List>
                  </Container>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </div>
      </div>
    );
  }
}
