import React, { Component } from "react";
import { Form, Segment, Header, Button } from "semantic-ui-react";

let warningStatus = false;
let resultMessage = "";
let sendingMessage = false;

export default class ContactForm extends Component {
  state = {
    name: "",
    email: "",
    phone: "",
    message: ""
  };

  sendMessage = data => {
    try {
      sendingMessage = true;
      resultMessage = "";
      this.setState({});
      fetch(
        "https://us-central1-vistaar-industries-website.cloudfunctions.net/sendEmail",
        {
          method: "POST", // or 'PUT'
          body: JSON.stringify(data), // data can be `string` or {object}!
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
        .then(res => res.json())
        .then(data => {
          resultMessage = data.result;
          sendingMessage = false;
          this.setState({});
        });
    } catch (e) {
      resultMessage = "There was an error Sending Message!";
      sendingMessage = false;
      this.setState({});
    }
  };

  handleFieldChange = e => {
    warningStatus = false;
    resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState(prevState => {
      return {
        [fieldName]: value
      };
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach(key => {
        let value = this.state[key];
        if (value.trim() === "") {
          valid = false;
        }
      });
    }
    return valid;
  };

  render() {
    return (
      <div>
        <Segment loading={sendingMessage}>
          <Form onSubmit={this.handleFormSubmit} warning={warningStatus}>
            {warningStatus && (
              <Header color="red"> Please fill data correctly!</Header>
            )}
            <Header color="green">{resultMessage}</Header>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="Name"
                type="text"
                value={this.state.name}
                name="name"
                onChange={this.handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Phone</label>
              <input
                placeholder="Phone"
                type="number"
                name="phone"
                value={this.state.phone}
                onChange={this.handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Email</label>
              <input
                placeholder="Email"
                type="text"
                name="email"
                value={this.state.email}
                onChange={this.handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Message</label>
              <textarea
                name="message"
                value={this.state.message}
                onChange={this.handleFieldChange}
              />
            </Form.Field>
            <Form.Field>
              <Button color="orange" onClick={this.handleFormSubmit}>
                Send Message
              </Button>
            </Form.Field>
          </Form>
        </Segment>
      </div>
    );
  }
}
