import React, { Component } from "react";
import { List, Container, Divider, Image } from "semantic-ui-react";

export default class InsulatorDesignTab extends Component {
  render() {
    return (
      <div style={{ marginBottom: "2%", width: "100%" }}>
        <Image centered size="big" src="assets/process/insulator_design.png" />
        <br />
        <Divider />
        <Container textAlign="left">
          <p className="small-text" style={{ fontWeight: "bold" }}>
            Silicone Composite Insulators consists of 3 parts
          </p>
          <List as="ul">
            <List.Item as="li">
              <p className="small-text" style={{ fontWeight: "bold" }}>
                FRP Rod Core
              </p>
              <p className="small-text">
                Polymer insulator needs of high quality FRP (Fiber Reinforced
                Plastic) rod. This rod is made by excellent Alignment &
                distribution of ECR glass fibers dipped in epoxy resin. This
                providers maximum mechanical strength and gives electric
                resistance as well.
              </p>
            </List.Item>
            <br />
            <List.Item as="li">
              <p className="small-text" style={{ fontWeight: "bold" }}>
                Weather Sheds
              </p>
              <p className="small-text">
                FRP Rod is then covered with silicone rubber housing using state
                of art injection molding machine. The covering is called
                “Weather Sheds”. Silicone Rubber weather sheds provide
                resistance from pollution, UV and gives Anti Tracking and Anti
                Erosion properties and necessary creepage.
              </p>
            </List.Item>
            <br />
            <List.Item as="li">
              <p className="small-text" style={{ fontWeight: "bold" }}>
                End Fitting
              </p>
              <p className="small-text">
                Metal End fittings are made of forged steel or Spheroidal
                Graphite Cast Iron or MCI. All the fittings exposed to
                Environment are galvanized and then crimped to FRP Rod with high
                precision crimping machine.
              </p>
            </List.Item>
          </List>
        </Container>
      </div>
    );
  }
}
