import React, { Component } from "react";
import { Grid, Container, Image } from "semantic-ui-react";

export default class Marking extends Component {
  render() {
    return (
      <div>
        <Grid width="16" stackable padded>
          <Grid.Row style={{ padding: "0px 0px 0px 0px" }}>
            <Grid.Column
              width="8"
              style={{ padding: "2% 2%" }}
              verticalAlign="middle"
            >
              <Container textAlign="center">
                <p
                  className="big-heading"
                  style={{ fontWeight: "bold", color: "orange" }}
                >
                  Marking
                </p>

                <p className="large-text">
                  We use highly sophisticated laser printing machine to print
                  batch no’s on each and every insulator produced for better
                  traceability of the product supplied.
                </p>
              </Container>
            </Grid.Column>
            <Grid.Column verticalAlign="middle" width="8">
              <Image centered fluid src="assets/manufac/marking.png" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
